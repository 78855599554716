<template>
    <OptionPicker v-if="showDoc" @close="showDoc = false"></OptionPicker>
    <div class="about-details">
        <Header title="training.president"></Header>
    </div>
    <div class="content-block md:p-28 p-12 leading-8 text-base md:text-justify" v-html="$t('president.content')">
    </div>
    <div class="footer-section">
        <Footer @shoWOption="showDoc = true"></Footer>
    </div>
</template>

<script setup>
import Header from '../../components/Items/Header.vue';
import Footer from '../../components/globals/Footer.vue';

import { ref } from 'vue';
import OptionPicker from '../../components/globals/OptionPicker.vue';

const showDoc = ref(false)

</script>